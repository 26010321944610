export const getEnvironmentVariable = (name: string) => {
  const value1 = typeof process !== "undefined" ? process.env[name] : undefined;

  const value2 =
    // @ts-ignore
    typeof env !== "undefined" ? env[name] : undefined;

  // @ts-ignore
  const value3 = typeof window !== "undefined" ? window[name] : undefined;

  // @ts-ignore
  const value4 =
    // @ts-ignore
    typeof import.meta !== "undefined" && typeof import.meta.env !== "undefined"
      ? // @ts-ignore
        import.meta.env[name]
      : undefined;

  return value1 ?? value2 ?? value3 ?? value4;
};
