import { generateCloudinaryUrl } from "~/utils/generateCloudinaryUrl";

export const Image = ({
  alt,
  cloudinaryId,
  width,
  height,
  className,
  srcSets,
  forceJpg,
}: {
  alt: string;
  cloudinaryId: string;
  width: number;
  height: number;
  className?: string;
  forceJpg?: boolean;
  srcSets?: {
    width: number;
    height: number;
  }[];
}) => {
  const src = generateCloudinaryUrl(cloudinaryId, width, height, forceJpg);
  const srcSet = srcSets
    ? srcSets.map((srcSet) => {
        const src = generateCloudinaryUrl(
          cloudinaryId,
          srcSet.width,
          srcSet.height,
          forceJpg,
        );
        return `${src} ${srcSet.width}w`;
      })
    : [];

  return (
    <img
      alt={alt}
      src={src}
      width={width}
      height={height}
      className={className}
    />
  );
};
