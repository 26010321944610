import { getEnvironmentVariable } from "./getEnvironmentVariable";

export const generateCloudinaryUrl = (
  cloudinaryId: string,
  width: number,
  height: number,
  forceJpg?: boolean,
) => {
  const CLOUDINARY_ACCOUNT_ID = getEnvironmentVariable(
    "VITE_CLOUDINARY_ACCOUNT_ID",
  );

  let baseSrc = `https://res.cloudinary.com/${CLOUDINARY_ACCOUNT_ID}/image/upload`;

  let src = baseSrc;
  if (forceJpg) {
    src += "/f_jpg";
  } else {
    src += "/f_auto";
  }
  src += "/q_auto";
  src += `/c_scale,w_${width},h_${height}`;
  src += `/${cloudinaryId}`;

  return src;
};
